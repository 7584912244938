<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12" md="1" lg="1">
          </v-col>

          <v-col cols="12" md="10" lg="10">
            <v-row>
              <v-col cols="10">
                <router-link :to="`/`" class="text-decoration-none text-white">
                  <v-toolbar-title class="mr-4">Prompt Template</v-toolbar-title>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link :to="`/me`" class="text-md-right text-decoration-none text-white">
                  <p class="">Account</p>
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pa-0">
                <v-content>
                  <slot></slot>
                </v-content>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="1" lg="1">
          </v-col>
        </v-row>
      </v-container>


      <v-footer class="text-center d-flex flex-column mt-10">
        <!-- Social Media Icons -->
        <div>
          <!-- 'mdi-facebook', 'mdi-twitter', 'mdi-linkedin' -->
          <v-btn class="mx-4" icon="mdi-facebook" variant="text"></v-btn>
          <v-btn class="mx-4" icon="mdi-twitter" variant="text"></v-btn>
          <v-btn class="mx-4" icon="mdi-linkedin" variant="text"></v-btn>
        </div>

        <!-- Contact Information -->
        <div class="pt-0">
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <p>
                Have questions or need assistance? Reach out to our support team:
              </p>
            </v-col>
          </v-row>
        </div>

        <!-- Community Links -->
        <v-divider></v-divider>
        <div>
          <v-row>
            <v-col>
              <p>Become a part of our growing community:</p>
              <v-btn class="text-caption" text>Leave us a message</v-btn>
              
              <router-link :to="`/faq`" class="text-decoration-none text-white">
                <v-btn class="text-caption" text>FAQs</v-btn>
              </router-link>
              

            </v-col>
          </v-row>
        </div>

        <!-- Legal Information -->
        <v-divider></v-divider>
        <div>
          <v-row>
            <v-col>
              <p>© {{ new Date().getFullYear() }} Prompt Template. All rights reserved.</p>
            </v-col>
          </v-row>
        </div>
      </v-footer>

    </v-main>
  </v-app>
</template>



<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DefaultLayout",
});
</script>
