
export const convertCamelCaseToWords = (str) => {
    if (str.length === 0) {
        return "";
    }
  
    let words = '';
    let word = str[0];
  
    for (let i = 1; i < str.length; i++) {
        if (str[i] === str[i].toUpperCase()) {
            if (str[i - 1] !== ' ' && str[i - 1] !== undefined && str[i - 1] !== str[i - 1].toUpperCase()) {
                words += ' ';
            }
            words += str[i];
        } else {
            word += str[i];
        }
    }
  
    words = word + words;
    
    words = words.charAt(0).toUpperCase() + words.slice(1);
    
    return words;
  }

  export const formatDateToString = (_date) => {
    const date = new Date(_date)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }